import { LoanDto, Role } from "src/backend";
import { BasicLoanRoleDtoExtended } from "src/services/loanApi";

export const canUserIdLoanRoleAcceptElements = (userRole: Role, userId: string, loanRoles: BasicLoanRoleDtoExtended[]) => {
    if (userRole === 'MANAGER_LENDER') {
        return true;
    }
    const loggedInUserLoanRole = loanRoles.find(loanRole => loanRole.user.id === userId);
    if (!loggedInUserLoanRole) {
        return false;
    }
    if (loggedInUserLoanRole) {
        return loggedInUserLoanRole.canAcceptFiles;
    }

    if (['BORROWER', 'LEAD_BORROWER'].includes(loggedInUserLoanRole.role)) {
        return false;
    }

    return false;
}