import { useState } from "react";
import { ElementStatusType, FormElementV2RequestDto, FormElementV2ResponseDto, LoanDto } from "src/backend";
import { useUser } from "src/hooks/use-user";
import { BasicLoanRoleDtoExtended } from "src/services/loanApi";
import { useCreateNoteMutation } from "src/services/noteApi";
import { useUpdateElementsMutation } from "src/services/packageApi";
import { getLoanFormElements } from "src/slices/form-element";
import { useDispatch } from "src/store";
import { canUserIdLoanRoleAcceptElements } from "src/utils/form-element/can-user-id-loan-role-accept-elements";

export interface FormElementStatusProps {
    id: string;
    loanId: string;
    loanRoles: BasicLoanRoleDtoExtended[];
    withPortal?: boolean;
    hidden: boolean;
    title: string;
    status: FormElementV2ResponseDto['status'];
}

export const useFormElementStatusState = (props: FormElementStatusProps) => {
    const [isRejectConfirmOpen, setIsRejectConfirmOpen] = useState(false);
    const { user } = useUser();
    const [updateElements] = useUpdateElementsMutation();
    const [createNote] = useCreateNoteMutation();
    const dispatch = useDispatch();

    const handleCreateNote = async (args: { note: string, includeWithMessage: boolean }) => {
        createNote({
            content: args.note,
            authorId: user.id,
            id: null,
            includeWithMessages: args.includeWithMessage,
            visibility: args.includeWithMessage ? 'ALL' : 'LENDER_TEAM',
            loanId: props.loanId,
            objectId: props.id,
            noteType: 'REJECTION',
            objectType: 'PACKAGE_INFO',
            notifyUsersIds: [],
            status: null
        });
    };

    const handleStatusChangeConfirm = async (args: { status: ElementStatusType, note?: string, includeWithMessage?: boolean }) => {
        const payload: Partial<FormElementV2RequestDto> & { loanId: string, id: string } = {
            loanId: props.loanId,
            id: props.id,
        }
        if (args.status === 'REJECTED') {
            payload['rejectedByUserId'] = user.id;
            payload['rejected'] = true;
        } else if (args.status === 'ACCEPTED') {
            payload['approvedByUserId'] = user.id;
        } else {
            payload['status'] = args.status;
        }
        if (args.note) {
            await handleCreateNote({
                note: args.note,
                includeWithMessage: args.includeWithMessage,
            });
        }
        await updateElements({
            multiSelect: false,
            elements: [payload],
        });
        dispatch(getLoanFormElements(props.loanId, true));
    }

    const handleStatusChange = async (status: ElementStatusType) => {
        if (status === 'REJECTED') {
            setIsRejectConfirmOpen(true);
        } else {
            await handleStatusChangeConfirm({ status });
        }
    }

    const mainStatusIcon = !['ACCEPTED'].includes(props.status)
        ? 'ACCEPTED'
        : 'REVIEWING';

    const hasMenu = !!StatusMap[props.status]

    const menuItems = StatusMap[props.status] || [];

    const isAcceptStatusEnabled = canUserIdLoanRoleAcceptElements(user.loggedCompanyRole, user.id, props.loanRoles);

    const filteredMenuItems = menuItems.filter(status => status !== "ACCEPTED" || isAcceptStatusEnabled);

    return {
        isRejectConfirmOpen,
        handleStatusChange,
        setIsRejectConfirmOpen,
        handleStatusChangeConfirm,
        mainStatusIcon,
        hasMenu,
        menuItems: filteredMenuItems,
    } as const;
}

const StatusMap: Record<ElementStatusType, ElementStatusType[]> = {
    ["OPEN"]: [],
    ["ACCEPTED"]: [
        "REVIEWING",
        "REJECTED",
        "NEEDS_LEGAL_REVIEW",
    ],
    ['REVIEWING']: [
        'ACCEPTED',
        'REJECTED',
    ],
    ['NEEDS_ATTENTION']: [
        'ACCEPTED',
        'REJECTED',
    ],
    ['REJECTED']: [
        'ACCEPTED',
        'REVIEWING',
    ],
    ['NEEDS_LEGAL_REVIEW']: [
        'ACCEPTED',
        'REJECTED',
    ],
    ['SUBMITTED']: [
        'ACCEPTED',
        'REVIEWING',
        'REJECTED',
    ],
    ["IN_PROGRESS"]: [
        "ACCEPTED",
        "REJECTED",
        "REVIEWING",
    ],
}

