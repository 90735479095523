import { LoanRoleDto, PackageInfoSharingResponseDto } from "src/backend";
import { TeamMemberSelectListAssignedListItem } from "src/components/v2/team-member-select-list/team-member-select-list.types";
import { useAuth } from "src/hooks/use-auth";
import { getElementSharedInfoTeams } from "src/utils/form-element/get-element-shared-info-teams";
import { mapSharedInfoToAssignListItem } from "src/utils/form-element/map-shared-info-to-assign-list-item";

interface SharedWithTeamsProps {
    sharedInfo: PackageInfoSharingResponseDto[];
    loanRoles: Pick<LoanRoleDto, 'user' | 'role' | 'labels' | 'id' | 'keyContact'>[];
}

export const useSharedWithTeams = (props: SharedWithTeamsProps) => {
    const { user } = useAuth();


    const assignedList: TeamMemberSelectListAssignedListItem[] = mapSharedInfoToAssignListItem({
        sharedInfo: props.sharedInfo,
        loanRoles: props.loanRoles,
        loggedInUserId: user.id
    });

    const teams = getElementSharedInfoTeams(assignedList);

    return {
        teams
    } as const;
};